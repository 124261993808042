type NonFunctional<T> = T extends object ? never : T;

//  FIXME: Fix this function so that it returns proper types

/**
 * Helper to produce an array of enum values.
 * @param enumeration Enumeration object.
 */
export function enumToArray<T>(enumeration: object): NonFunctional<T[keyof T]>[] {
  return Object.keys(enumeration)
    .filter(key => isNaN(Number(key)))
    .map(key => enumeration[key as keyof object])
    .filter(val => typeof val === 'number' || typeof val === 'string');
}
