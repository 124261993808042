import { Pipe, PipeTransform } from '@angular/core';

/**
 * Format string in the input form when input type is 'month'
 */
@Pipe({
  name: 'monthDatePicker',
  standalone: true,
})
export class MonthDatePickerPipe implements PipeTransform {
  public transform(date: Date): string {
    return new Date(date).toISOString().substring(0, 7);
  }
}
