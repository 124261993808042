import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uppercaseRequired',
  standalone: true,
})
export class UppercaseRequirementPipe implements PipeTransform {
  public transform(password: string): boolean {
    const regex = /[A-Z]/;

    return regex.test(password);
  }
}
