import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'matchRequirement',
  standalone: true,
})
export class PassMatchRequirementPipe implements PipeTransform {
  public transform(password: string, repeatPassword: string): boolean {
    return password === repeatPassword;
  }
}
