<div [ngClass]="containerClasses" class="wh-s-input--container {{ isFocused ? 'focused' : '' }}">
  <!-- Animated label -->
  @if (type === 'date' || type === 'number') {
    <p class="wh-s-label-above-input {{ required ? 'required' : '' }}">
      {{ label }}
    </p>
  } @else {
    @if (!!placeholder || (!!_val && disabled)) {
      <!--   State where input HAS value value AND HAS placeholder    -->
      <div class="wh-s-label-above-input">
        {{ label }}
      </div>
    } @else if (!placeholder) {
      <!--   State where input has value but it's disabled   -->
      <div class="wh-s-input--label {{ !!_val ? 'stick-top' : '' }} {{ required ? 'required' : '' }}">
        {{ label }}
      </div>
    }
  }

  <!-- Icon container (BEFORE input) -->
  <div class="wh-s-input-icon-before" (click)="iconClick.emit($event)">
    <i-feather *ngIf="variant === 'danger' && !isFocused" class="icon-before" name="x-octagon"></i-feather>
    <i-feather *ngIf="variant === 'success' && !isFocused" class="icon-before" name="check-circle"></i-feather>
    <i-feather *ngIf="disabled" class="icon-before" name="slash"></i-feather>
  </div>

  <!-- Input -->
  <input
    name="wh-s-input"
    class="wh-s-input"
    [readonly]="readonly"
    [type]="type"
    [ngClass]="inputClasses"
    [disabled]="disabled"
    [placeholder]="placeholder"
    [(ngModel)]="value"
    (ngModelChange)="onChange($event)"
    (input)="onInputChange()"
    (focusin)="isFocused = true"
    (focusout)="isFocused = false"
  />

  <!-- Icon container (AFTER input) -->
  <div class="wh-s-input-icon-after" (click)="iconClickEmitter.emit($event)">
    <fa-icon *ngIf="iconAfter" class="icon-after" [icon]="iconAfter"></fa-icon>

    <wh-s-spinner *ngIf="(loadingViewModel.isLoading$ | async) && showLoading"></wh-s-spinner>

    <ng-container *ngIf="type === 'password' || passwordOn">
      <fa-icon *ngIf="passwordOn" class="icon-after" [icon]="passwordEyeIconOn" (click)="toggleEyePassword()"></fa-icon>

      <fa-icon
        *ngIf="!passwordOn"
        class="icon-after"
        [icon]="passwordEyeIconOff"
        (click)="toggleEyePassword()"
      ></fa-icon>
    </ng-container>
  </div>
</div>

<!-- Message wrapper -->
<div
  *ngIf="
    (hasMessageWrapper && variant === 'danger' && !isFocused) ||
    (hint && !isFocused) ||
    (errorLabelAsTranslocoString && missingRequiredFieldErrorLabel)
  "
  class="message-wrapper"
>
  <p *ngIf="hint && !isFocused" class="wh-s-input--hint">{{ hint }}</p>
  <p *ngIf="variant === 'danger' && !isFocused" class="wh-s-input--hint--danger">{{ errorLabel }}</p>

  @if (errorLabelAsTranslocoString && variant === 'danger' && !isFocused) {
    <p class="wh-s-input--hint--danger">{{ errorLabelAsTranslocoString | transloco }}</p>
  }
  @if (missingRequiredFieldErrorLabel && variant === 'danger' && !isFocused) {
    <p class="wh-s-input--hint--danger">{{ missingRequiredFieldErrorLabel | transloco }}</p>
  }

  @if (errorLabels.length && variant === 'danger' && !isFocused) {
    @for (error of errorLabels; track error) {
      <p class="wh-s-input--hint--danger">{{ error }}</p>
    }
  }
</div>
