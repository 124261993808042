import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import { ApplicantTrackingSystemApi, JobApi, JobApplicationApi } from '@web/shared/data-access/model';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ApplicantAuthViewModel } from '@web/web/applicant/core/auth/data-access';
import { AimwelService } from '@web/web/shared/data-access/aimwel';
import { AlertService } from '@web/web/shared/data-access/alert';
import { JobApiService, JobApplicationApiService } from '@web/web/shared/data-access/api';
import { JobrapidoService } from '@web/web/shared/data-access/jobrapido';
import { JoobleService } from '@web/web/shared/data-access/jooble';
import { Observable, catchError, of, switchMap, take, tap } from 'rxjs';
import { UtmLocalStorageService } from 'web/shared/data-access/utm';

export interface ExternalApplicationState {
  jobId: string;
  jobDetails?: JobApi.JobDetails;
  abortDialogOpened?: boolean;
  abortApplication?: JobApplicationApi.AbortApplication;
  zapier?: boolean;
  isAssessmentPassed?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ExternalApplicationViewModel extends ComponentStore<ExternalApplicationState> {
  public readonly vm$: Observable<ExternalApplicationState> = this.select(state => ({
    jobId: state.jobId,
    jobDetails: state.jobDetails,
    abortDialogOpened: state.abortDialogOpened,
    abortApplication: state.abortApplication,
    zapier: state.zapier,
    isAssessmentPassed: state.isAssessmentPassed,
  }));

  constructor(
    private readonly router: Router,
    public readonly alertService: AlertService,
    public readonly jobApiService: JobApiService,
    public readonly jobApplicationApiService: JobApplicationApiService,
    private readonly applicantAuthViewModel: ApplicantAuthViewModel,
    private readonly utmLocalStorageService: UtmLocalStorageService,
    private readonly jobrapidoService: JobrapidoService,
    private readonly joobleService: JoobleService,
    private readonly aimwelService: AimwelService,
  ) {
    super({
      jobId: '',
    });
  }

  public setJobId(jobId: string): void {
    this.patchState({ jobId });
  }

  public setZapier(zapier: boolean): void {
    this.patchState({ zapier });
  }

  public setIsAssessmentPassed(isAssessmentPassed: boolean | undefined): void {
    this.patchState({ isAssessmentPassed: isAssessmentPassed });
  }

  public getJobDetails(): void {
    const id = this.get().jobId;
    this.jobApiService
      .getJobDetailsForExternalApplication(id)
      .pipe(
        take(1),
        tap(jobDetails => {
          this.patchState({ jobDetails });
        }),
      )
      .subscribe();
  }

  public createApplication(redirectToLogin = false): void {
    const jobDetails: JobApplicationApi.JobApplication = {
      jobId: String(this.get().jobId),
      source: ApplicantTrackingSystemApi.Source.EXTERNAL,
      utm: this.utmLocalStorageService.getUtmData(),
      abort: this.get().abortApplication ?? undefined,
      zapier: this.get().zapier,
      isAssessmentPassed: this.get().isAssessmentPassed,
    };

    this.applicantAuthViewModel.isAuthenticated$
      .pipe(
        take(1),
        switchMap(isAuthenticated => {
          const registerData = localStorage.getItem('create_account_data_key' ?? '');
          if (isAuthenticated && !registerData) {
            return this.jobApplicationApiService.create(jobDetails).pipe(
              take(1),
              tap(jobApplication => {
                this.jobrapidoService.sendJobrapidoApplicationCompleteEvent();
                this.joobleService.sendJoobleApplicationCompleteEvent();
                this.aimwelService.sendAimwelApplicationCompleteEvent();

                if (jobApplication && jobApplication.currentStep?.assessment) {
                  this.router.navigate([`/jobs/assessment/${jobApplication.currentStep?.assessment.id}`]);
                } else {
                  if (jobApplication) {
                    this.alertService.success('You have successfully applied for the job.');
                  }
                  this.applicantAuthViewModel.removeExternalApplicationData();

                  this.router.navigate(['/jobs']);
                }

                this.utmLocalStorageService.removeUtmData();
              }),
              catchError(error => {
                if (error.error.statusCode === 409) {
                  this.alertService.info('You have already applied for this job.');

                  this.applicantAuthViewModel.removeExternalApplicationData();

                  this.router.navigate([`/`]);

                  return of({});
                } else {
                  return of({});
                }
              }),
            );
          } else {
            this.applicantAuthViewModel.setExternalApplicationData(jobDetails);

            if (redirectToLogin) {
              return this.router.navigate([`/auth/login`], { queryParamsHandling: 'merge' });
            }

            return this.router.navigate([`/auth/register`], { queryParamsHandling: 'merge' });
          }
        }),
      )
      .subscribe();
  }

  public toggleAbortDialog(): void {
    const abortDialogOpened = this.get().abortDialogOpened;

    this.patchState({ abortDialogOpened: !abortDialogOpened });
  }

  public abortApplication(abortApplication: JobApplicationApi.AbortApplication): void {
    this.patchState({ abortApplication });
    this.createApplication();
    this.toggleAbortDialog();
  }
}
