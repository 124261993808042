import { Injectable } from '@angular/core';
import { AimwelApiService } from '@web/web/shared/data-access/api';
import { catchError, of } from 'rxjs';

/**
 * https://docs.google.com/document/d/101L0FQPC3uk8dpWbnQpvKDO1Jjk5EfU2QVCBZSjDO8A/edit?tab=t.0
 */
@Injectable({ providedIn: 'root' })
export class AimwelService {
  public session_id: string;
  public job_id?: string;
  public utm_campaign?: string;
  public utm_source?: string;
  public utm_medium?: string;
  public utm_term?: string;
  public utm_content?: string;
  public chan_ref: string;
  public aw_bid_configuration_id: string;
  public aw_campaign_id: string;
  public aw_settings_id: string;
  public aw_id: string;

  constructor(private readonly aimwelApiService: AimwelApiService) {}

  public sendAimwelClickValidityEvent(): void {
    if (!this.session_id || !this.job_id) {
      return;
    }

    this.aimwelApiService
      .sendAimwelFeedbackEvent({
        session_id: this.session_id,
        job_id: this.job_id,
        timestamp: new Date().getTime(),
        event_type: 'view',
        utm_campaign: this.utm_campaign,
        utm_source: this.utm_source,
        utm_medium: this.utm_medium,
        utm_term: this.utm_term,
        utm_content: this.utm_content,
        chan_ref: this.chan_ref,
        aw_id: this.aw_id,
        aw_bid_configuration_id: this.aw_bid_configuration_id,
        aw_campaign_id: this.aw_campaign_id,
        aw_settings_id: this.aw_settings_id,
        client_side: true,
      })
      .pipe(
        catchError(error => {
          // eslint-disable-next-line no-console
          console.warn(error);

          return of(null);
        }),
      )
      .subscribe();
  }

  public sendAimwelApplicationStartEvent(): void {
    if (!this.session_id || !this.job_id) {
      return;
    }

    this.aimwelApiService
      .sendAimwelFeedbackEvent({
        session_id: this.session_id,
        job_id: this.job_id,
        timestamp: new Date().getTime(),
        event_type: 'apply',
        utm_campaign: this.utm_campaign,
        utm_source: this.utm_source,
        utm_medium: this.utm_medium,
        utm_term: this.utm_term,
        utm_content: this.utm_content,
        chan_ref: this.chan_ref,
        aw_id: this.aw_id,
        aw_bid_configuration_id: this.aw_bid_configuration_id,
        aw_campaign_id: this.aw_campaign_id,
        aw_settings_id: this.aw_settings_id,
        client_side: true,
      })
      .pipe(
        catchError(error => {
          // eslint-disable-next-line no-console
          console.warn(error);

          return of(null);
        }),
      )
      .subscribe();
  }

  public sendAimwelApplicationCompleteEvent(): void {
    if (!this.session_id || !this.job_id) {
      return;
    }

    this.aimwelApiService
      .sendAimwelFeedbackEvent({
        session_id: this.session_id,
        job_id: this.job_id,
        timestamp: new Date().getTime(),
        event_type: 'finished_apply',
        utm_campaign: this.utm_campaign,
        utm_source: this.utm_source,
        utm_medium: this.utm_medium,
        utm_term: this.utm_term,
        utm_content: this.utm_content,
        chan_ref: this.chan_ref,
        aw_id: this.aw_id,
        aw_bid_configuration_id: this.aw_bid_configuration_id,
        aw_campaign_id: this.aw_campaign_id,
        aw_settings_id: this.aw_settings_id,
        client_side: true,
      })
      .pipe(
        catchError(error => {
          // eslint-disable-next-line no-console
          console.warn(error);

          return of(null);
        }),
      )
      .subscribe();
  }
}
