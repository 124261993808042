import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { AlertService } from '@web/web/shared/data-access/alert';
import { Observable, catchError, throwError } from 'rxjs';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  /**
   * Catch first error on page load (app tries to generate new accessToken with the help of a refresh token)
   * When user refresh page, prevent error popup
   */
  public isFirstError = true;
  constructor(
    private readonly router: Router,
    private readonly alertService: AlertService,
  ) {}

  public intercept<T, U>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<U>> {
    return next.handle(req).pipe(
      catchError(error => {
        /**
         * Do not trigger alert service if error is not coming from HTTP request
         * validate token request will also be ignored
         */
        if (
          !(error instanceof HttpErrorResponse) ||
          (req.url.includes('validate') && this.isFirstError) ||
          this.router.url.includes('promotion') // TODO: think about handling these edge cases in a better way
        ) {
          this.isFirstError = false;

          Sentry.captureException({
            message: `HTTP Error: ${error.message}`,
            url: req.url,
            status: error.status,
            body: error.error,
          });

          return throwError(() => error);
        }

        if (error.error.statusCode !== 409) {
          this.alertService.error(error.error.message ?? 'shared.ui.alert.generic-error');
        }

        // eslint-disable-next-line no-console
        console.warn('Error that occurred: ', error);

        return throwError(() => error);
      }),
    );
  }
}
