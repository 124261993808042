import { JobApi } from './job-api.namespace';

export namespace JobFeedApi {
  export interface JobrapidoJobFeedElement {
    id: string;
    job: JobApi.Job;
    applicationCount?: number;
    budgetCap: number;
    costPerClickCap: number;
    applicationCap: number;
    isVisible: boolean;
    isApplicationCountIncludedInFeed: boolean;
  }

  export interface JoobleJobFeedElement {
    id: string;
    job: JobApi.Job;
    applicationCount?: number;
    budgetCap: number;
    costPerClickCap: number;
    applicationCap: number;
    isVisible: boolean;
    isApplicationCountIncludedInFeed: boolean;
  }

  export interface AimwelJobFeedElement {
    id: string;
    job: JobApi.Job;
    applicationCount?: number;
    budgetCap: number;
    costPerClickCap: number;
    applicationCap: number;
    isVisible: boolean;
    isApplicationCountIncludedInFeed: boolean;
  }

  export interface UpdateJobrapidoJobFeedElement {
    id: string;
    budgetCap: number;
    costPerClickCap: number;
    applicationCap: number;
    isVisible: boolean;
    isApplicationCountIncludedInFeed: boolean;
  }

  export interface UpdateJoobleJobFeedElement {
    id: string;
    budgetCap: number;
    costPerClickCap: number;
    applicationCap: number;
    isVisible: boolean;
    isApplicationCountIncludedInFeed: boolean;
  }

  export interface UpdateAimwelJobFeedElement {
    id: string;
    budgetCap: number;
    costPerClickCap: number;
    applicationCap: number;
    isVisible: boolean;
    isApplicationCountIncludedInFeed: boolean;
  }

  export enum JobFeedElementStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
  }
}
