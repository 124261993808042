import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lowercaseRequired',
  standalone: true,
})
export class LowercaseRequirementPipe implements PipeTransform {
  public transform(password: string): boolean {
    const regex = /[a-z]/;

    return regex.test(password);
  }
}
