import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberRequired',
  standalone: true,
})
export class NumberRequirementPipe implements PipeTransform {
  public transform(password: string): boolean {
    const regex = /\d/;

    return regex.test(password);
  }
}
