import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'symbolRequired',
  standalone: true,
})
export class SymbolRequirementPipe implements PipeTransform {
  public transform(password: string): boolean {
    const regex = /[!@#$%^&*(),.?":{}|<>_\-+=;\\[\]~`]/;

    return regex.test(password);
  }
}
