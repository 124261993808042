import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BASE_URL_TOKEN } from './config/api-token';

@Injectable({
  providedIn: 'root',
})
export class AimwelApiService {
  private readonly url: string;
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(BASE_URL_TOKEN) private readonly baseUrl: string,
  ) {
    this.url = `${baseUrl}/job-feeds/aimwel/postback`;
  }

  public sendAimwelFeedbackEvent(aimwelFeedbackEvent: {
    session_id: string;
    job_id: string;
    timestamp: number;
    event_type: 'apply' | 'view' | 'finished_apply';
    utm_campaign: string | undefined;
    utm_source: string | undefined;
    utm_medium: string | undefined;
    utm_term: string | undefined;
    utm_content: string | undefined;
    chan_ref: string;
    aw_bid_configuration_id: string;
    aw_campaign_id: string;
    aw_settings_id: string;
    client_side: boolean;
    aw_id: string;
  }): Observable<void> {
    return this.httpClient.post<void>(this.url, aimwelFeedbackEvent);
  }
}
