import { ApplicantApi } from './applicant-api.namespace';
import { ApplicantApprenticeshipApi } from './applicant-apprenticeship-api.namespace';
import { ApplicantEducationApi } from './applicant-education-api.namespace';
import { ApplicantLanguageApi } from './applicant-language-api.namespace';
import { ApplicantWorkExperienceApi } from './applicant-work-experience-api.namespace';
import { CertificateApi } from './certificate-api.namespace';
import { DriverLicenseApi } from './driver-license-api.namespace';
import { NoteApi } from './note-api.namespace';
import { RecruitmentProcessApi } from './recruitment-process-api.namespace';
import { TextVariantApi } from './text-variant-api.namespace';
import { TranslocoApi } from './transloco-api.namespace';

export namespace ApplicantTrackingSystemApiNew {
  export enum Tab {
    PROFILE = 'PROFILE',
    NOTE = 'NOTE',
    RECRUITING_HISTORY = 'RECRUITING_HISTORY',
    AI_EVALUATION = 'AI_EVALUATION',
  }

  export interface JobApplicationListItem {
    id: string;
    applicant: string;
    applicantEmail: string;
    applicantId: string;
    jobName: TextVariantApi.TextVariant[];
    jobCity: string;
    companyName: string;
    currentStep: string;
    visibleSince: Date;
    createdAt: Date;
    isPotentialPick: boolean;
    isPick: boolean;
    isHidden: boolean;
    canChangePhase: boolean;
  }

  export interface PhaseInfo {
    jobApplicationId: string;
    availableSteps: RecruitmentProcessApi.RecruitmentStepSimple[];
  }

  export interface ChangePhase {
    jobApplicationId: string;
    newStepId: string;
    applicantNoFitReason?: RecruitmentProcessApi.ApplicantNoHireReason;
    noHireReason?: RecruitmentProcessApi.NoHireReason;
    noHireText?: string;
    note?: NoteApi.CreateNoteSimple;
    interviewMessage?: string;
    skipCommunication: boolean;
  }

  export interface SearchData {
    page: number;
    pageSize: number;
    searchTerm: string;
    filter: FilterData;
  }

  export interface FilterData {
    // jobs: []
    cities: string[];
    applicationTypes: string[];
    recruitmentProcessSteps: string[];
    timeInStepLessThanDays: number | null;
    timeInStepGreaterThanDays: number | null;
    order: string;
  }

  export enum ApplicationSource {
    INTERNAL = 'INTERNAL',
    EXTERNAL = 'EXTERNAL',
  }

  export interface ApplicantProfile {
    id: string;
    avatarUrl: string;
    address: string;
    firstName: string;
    lastName: string;
    birthDate: Date;
    nationality: TranslocoApi.Nationality;
    communicationLanguage: string;
    isAccountActive: boolean;
    email: string;
    phone: string;
    jobApplicationSource: ApplicationSource;
    isJobApplicationHidden: boolean;
    isPick: boolean;
    isPotentialPick: boolean;
    currentStep: string;
    canChangePhase: boolean;
  }

  export interface ApplicantProfessionalProfile {
    workExperiences: ApplicantWorkExperienceApi.WorkExperience[];
    educations: ApplicantEducationApi.Education[];
    apprenticeships: ApplicantApprenticeshipApi.Apprenticeship[];
    licenses: DriverLicenseApi.DriverLicenseApplicant[];
    certificates: CertificateApi.Certificate[];
    documents: ApplicantApi.DocumentItem[];
    language: ApplicantLanguageApi.Language[];
  }
}
