import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lengthRequirement',
  standalone: true,
})
export class LengthRequirementPipe implements PipeTransform {
  public transform(password: string, requiredLength = 8): boolean {
    return password.length >= requiredLength;
  }
}
