import { Pipe, PipeTransform } from '@angular/core';

/**
 * Use this pipe to get the last day of the month selected in the month picker
 */
@Pipe({
  name: 'untilDate',
  standalone: true,
})
export class UntilDatePipe implements PipeTransform {
  public transform(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }
}
